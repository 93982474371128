import {
  AmplitudeScript,
  Environment,
  EnvironmentIdentifier,
  GoogleTagManagerScript,
  HotjarScript,
  LeanScript,
  prypcoEnv,
} from '@aw/prypco/environment';

export const environment: Environment = {
  ...prypcoEnv,
  production: true,
  testing: false,
  noIndex: true,
  dev: false,
  externalScripts: {
    amplitude: new AmplitudeScript(
      '59a9af5fef605b099b685b1b3ce99f6c',
      {
        defaultTracking: {
          attribution: false,
          pageViews: false,
          sessions: false,
          formInteractions: false,
          fileDownloads: false,
        },
        optOut: false,
      },
      false,
      true,
    ),
    googleTagManager: new GoogleTagManagerScript('GTM-5JLMNCSW', false),
    hotjar: new HotjarScript(4988536, 6, true),
    lean: new LeanScript('3c93a8b1-577e-4161-bf30-45f205fcd451', true),
    gtm: false,
  },
  featureFlags: {
    notifications: false,
    agencyDetails: true,
    newBankOffers: true,
    mortgagePartners: false,
    devModule: false,
    devErrorInterceptor: false,
    blocks: {
      comingSoon: true,
      portal: true,
      backOffice: true,
      devPanel: false,
      payByCard: true,
      payInstantTransfer: true,
    },
    listing: {
      backOffice: true,
    },
    services: {
      backOffice: true,
      rnpl: true,
      goldenVisa: true,
    },
    mortgage: {
      allowReaChannelFederation: false,
    },
  },
  promos: {
    mortgage: false,
  },
  prypocOne: 'https://green.one.prypco.com/',

  identifiers: {
    [EnvironmentIdentifier.MapBoxKey]:
      'pk.eyJ1IjoicHJ5cHRvIiwiYSI6ImNsZmpvbmF5bzAzMHczdHBjaHhzdHN6NHUifQ.Odt7HXtV-hIbe9jswzzw0A',
  },
};
